import { Button, Form, Modal } from 'antd';
import styles from './index.module.less';

const CommonSimpleModal = ({
  style,
  title,
  desc,
  btnTitle,
  closeBtnTitle = null,
  open,
  closeAction,
}: {
  style?: React.CSSProperties;
  title?: string | JSX.Element;
  desc?: string | JSX.Element;
  btnTitle?: string | JSX.Element;
  closeBtnTitle?: string | JSX.Element;
  open: boolean;
  closeAction?: (isOk: boolean, e?: any) => void;
}) => {
  return (
    <Modal
      width={450}
      style={style}
      className={styles.modalContainer}
      open={open}
      onCancel={(e) => {
        if (closeAction) {
          closeAction(false, e);
        }
      }}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="normal_edit_form"
        layout="vertical"
        className={styles.formContent}
        style={{ padding: '20px 24px' }}
        onClick={(e) => { e?.stopPropagation() }}
      >
        {title && (typeof title === 'string' ? <div className={styles.titleContainer}>{title}</div> : title)}

        {desc && (
          typeof desc === 'string' ? <Form.Item>
            <div className={styles.desc}>{desc}</div>
          </Form.Item> : desc
        )}

        {(btnTitle || closeBtnTitle) && (
          <Form.Item>
            <div className={styles.btnsContainer}>
              {closeBtnTitle && <Button
                className={styles.bottomCancelButton}
                onClick={(e) => {
                  if (closeAction) {
                    closeAction(false, e);
                  }
                }}
              >
                {closeBtnTitle}
              </Button>}

              {btnTitle && <Button
                className={styles.bottomButton}
                // style={{ color: `#FFF`, background: `var(--app-primary-color)` }}
                onClick={(e) => {
                  if (closeAction) {
                    closeAction(true, e);
                  }
                }}
              >
                {btnTitle}
              </Button>}
            </div>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CommonSimpleModal;
