import { CaretDownOutlined, DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useLocation } from 'react-use';
import React, { MouseEvent, useState } from 'react';
import { useConfigProvider } from '@/context/ConfigProvider';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { Dropdown, Space, Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';
import { imgUrl } from '@/shared/utils';

export interface MarketplaceNavItem {
  text: string | React.ReactNode;
  path?: string;
  gaName?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}

const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const { t } = useTranslation();
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const location = useLocation();

  const getColor = (pathStr: string) => {
    let end =
      pathStr != '/' &&
      (location?.pathname?.includes('/toolkit')
        ? pathStr.includes('/toolkit')
        : location?.pathname?.toLowerCase().endsWith(pathStr?.toLowerCase()));
    let isStarData = router.pathname == '/starData' || router.pathname.startsWith('/top-instagram');
    return end
      ? 'var(--app-primary-color)'
      : isStarData && !isMobile
        ? '#ffffff'
        : `var(--app-text-color)`;
  };

  const getDropdownColor = (pathStr: string) => {
    return router.asPath === pathStr ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  // const handleItemClick = (e: any, item: MarketplaceNavItem) => {
  //   e.stopPropagation();
  //   if (item.path === '/toolkit' && isMobile) {
  //     setOpenToolkit(() => !openToolkit);
  //     return;
  //   }
  //   if (item.path === router?.pathname) return;
  //   if (item.gaName) {
  //     ga_logEvent(item?.gaName);
  //   }
  //   if (item?.onClick) {
  //     item.onClick(item);
  //     return;
  //   }
  //   router.push(item?.path || '/');
  // };

  const handleItemClick = (e: any, item: MarketplaceNavItem) => {
    if (item.path === router?.pathname) return;
    if (item.gaName) {
      ga_logEvent(item?.gaName);
    }
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    if (item?.path?.includes('https://blog-test.dolphinradar.com') || item?.path?.includes('https://blog.dolphinradar.com')) {
      window.open(item?.path, '_blank');
    } else {
      router.push(item?.path || '/');
    }
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={(e) => handleItemClick(e, item)}
      >
        <span
          className={isMobile ? styles.navItemMobileText : styles.navItemText}
          style={{
            color: getColor(item?.path),
            fontWeight: item?.path.includes('starData') || router.pathname.includes('instagram-top-accounts') ? 'bloder' : '',
          }}
        >
          {item.dropdownItems ? (
            <Dropdown
              menu={{
                items: item.dropdownItems.map((item, index) => {
                  return {
                    key: index,
                    label: (
                      <span
                        style={{ color: getDropdownColor(item?.path) }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemClick(e, item);
                        }}
                      >
                        {item.text}
                      </span>
                    ),
                  };
                }),
              }}
              placement="bottom"
              autoAdjustOverflow={false}
            >
              <div className={styles.navItemTextDiv}>
                <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
                  {item.text}
                </span>
                <CaretDownOutlined />
              </div>
            </Dropdown>
          ) : (
            <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
              {item.text}
            </span>
          )}
        </span>
      </div>
    );
  };

  return renderItem(item, `nav-item`);
};

interface ToolkitTitleProps {
  iconUrl?: string;
  title?: string;
  path?: string;
}
const ToolkitTitle = ({
  toolkitList,
  handleLink,
}: {
  toolkitList: ToolkitTitleProps[];
  handleLink: (e: MouseEvent<HTMLDivElement>, path: string) => void;
}) => {
  const router = useRouter();

  return (
    <div className={styles.tipList}>
      {toolkitList.map((item) => {
        const { path, iconUrl, title } = item;
        return (
          <div key={path} className={styles.tipItem} onClick={(e) => handleLink(e, path)}>
            <div className={styles.icon}>
              <img src={imgUrl(iconUrl, 'small')} alt={title} />
            </div>
            <span
              style={{
                color: router?.pathname?.includes(path) && 'var(--app-primary-color)',
              }}
            >
              {title}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default NavItem;
