import React, { useMemo } from 'react';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import './index.less';
import { AppSettings, PageType } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { EventName } from '@/shared/event-types';
import { useRouter } from 'next/router';
import { Flex } from 'antd';
import Image from 'next/image';

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  pageType?: PageType;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
  pageType = PageType.Normal,
}: LayoutProps) => {
  const { isMobile, isBlogWeb } = useConfigProvider();
  const { t } = useTranslation('common');
  // startData和其他样式区分
  const router = useRouter();
  // const ref = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           if (ref.current) {
  //             if (router.pathname.includes('starData')) {
  //               // const src = isMobile
  //               //   ? '/images/layouts/starBgkMob.webp'
  //               //   : '/images/layouts/starBgk.webp';
  //               // ref.current.style.backgroundImage = `url(${src})`;
  //               ref.current.style.marginTop = '0px';
  //             } else {
  //               // ref.current.style.backgroundImage = `url('/images/layouts/background-icon.webp')`;
  //               ref.current.style.marginTop = '62px';
  //             }
  //             if (!showHeader) {
  //               ref.current.style.marginTop = '0px';
  //             }
  //           }
  //           observer.disconnect();
  //         }
  //       });
  //     },
  //     { threshold: 0.1 },
  //   );
  //   if (ref.current) {
  //     observer.observe(ref.current);
  //   }
  //   return () => {
  //     if (ref.current) {
  //       observer.unobserve(ref.current);
  //     }
  //   };
  // }, [router.pathname.includes('starData'), showHeader]);

  const isInstagramTopAccounts = useMemo(() => {
    return router.pathname.includes('instagram-top-accounts') || router.pathname.includes('starData');
  }, [router.pathname]);

  const getMarginTop = useMemo(() => {
    let topMarginTop = '0px';
    if (isInstagramTopAccounts) {
      topMarginTop = '0px';
    } else {
      topMarginTop = '62px';
    }
    if (!showHeader) {
      topMarginTop = '0px';
    }
    return topMarginTop;
  }, [isInstagramTopAccounts, showHeader, isMobile]);

  // app sub title
  const getNavItems = useMemo(() => {
    return [
      // {
      //   text: t('home'),
      //   path: AppSettings.webHost,
      //   gaName: EventName.header_Home,
      // },
      {
        text: t('Analytics Sample'),
        path: isBlogWeb
          ? `${AppSettings.webHost}/instagram-tracking-example`
          : `/instagram-tracking-example`,
        gaName: EventName.header_Analytics_Sample,
      },
      {
        text: t('Celebrity Influencers'),
        path: isBlogWeb ? `${AppSettings.webHost}/top-instagram` : `/top-instagram`,
        gaName: EventName.header_star_data,
      },
      {
        text: t('Pricing'),
        path: isBlogWeb ? `${AppSettings.webHost}/plan` : `/plan`,
        gaName: EventName.header_Plan,
      },
      {
        text: t('blog'),
        path: isBlogWeb
          ? `/instagram-tracking-blog`
          : `${AppSettings.blogHost}/instagram-tracking-blog`,
        gaName: EventName.header_Blog,
      },
      {
        text: t('FAQ'),
        path: isBlogWeb
          ? `${AppSettings.webHost}/instagram-tracking-faq`
          : `/instagram-tracking-faq`,
        gaName: EventName.header_FAQ,
      },
      {
        text: t('Free Tools'),
        path: isBlogWeb ? `${AppSettings.webHost}/toolkit` : `/toolkit`,
        gaName: EventName.header_Toolkit,
        dropdownItems: [
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon1.png"
                  alt="Celebrity Influencers"
                  width={22}
                  height={22}
                  loading='lazy'
                />
                {t('Celebrity Influencers')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/top-instagram` : '/top-instagram',
            gaName: EventName.header_star_data,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon2.png"
                  alt="Celebrity Influencers"
                  width={22}
                  height={22}
                  loading='lazy'
                />
                {t('Instagram Story Viewer')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/view-instagram-anonymously` : '/view-instagram-anonymously',
            gaName: EventName.header_view_instagram_anonymously,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon3.png"
                  alt="Web viewer for Instagram"
                  width={22}
                  height={22}
                  loading='lazy'
                />
                {t('Web viewer for Instagram')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/web-viewer-for-instagram` : '/web-viewer-for-instagram',
            gaName: EventName.header_web_viewer_for_instagram,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon4.webp"
                  alt="Hashtag Generator for Instagram"
                  width={22}
                  height={22}
                  loading='lazy'
                />
                {t('Hashtag Generator For Instagram')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/instagram-hashtag-generator` : '/instagram-hashtag-generator',
            gaName: EventName.header_web_viewer_for_instagram,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon5.webp"
                  alt="Instagram Recent Follower Tracker"
                  width={22}
                  height={22}
                  loading='lazy'
                />
                {t('Instagram Recent Follower Tracker')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/instagram-new-follow-tracker` : '/instagram-new-follow-tracker',
            gaName: EventName.header_web_viewer_for_instagram,
          },
        ],
      },
    ];
  }, [AppSettings.type, isBlogWeb]);

  const headerContent = useMemo(() => {
    return (
      <>
        {showHeader && (
          <header className={'header'}>
            <GlobalHeader isMobile={isMobile} navItems={getNavItems} isStarData={isInstagramTopAccounts} />
          </header>
        )}
      </>
    );
  }, [showHeader, isMobile, getNavItems, router.pathname]);

  const footerContent = useMemo(() => {
    return (
      <>
        {showFooter && (
          <footer className={'footer'}>
            <GlobalFooter pageType={pageType} />
          </footer>
        )}
      </>
    );
  }, [showFooter, pageType]);

  return (
    <div className={'layout'}>
      {head}
      {headerContent}
      <main
        // ref={ref}
        style={{ marginTop: getMarginTop }}
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        {children}
      </main>
      {footerContent}
    </div>
  );
};
export default Layout;
